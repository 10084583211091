<template>
    <!-- form -->
    <form class="validate-form">
    <!-- core job detail -->
    <base-card-plain>
        <template #header>
            <h4 class="card-title">Add test questions to FRONT END TEST</h4>
            <base-card-drop-down>
                <template #title>
                    Add question options
                </template>
                <template #default>
                    <a class="dropdown-item" href="#">
                        <i class="bi bi-check-circle bs-icon-tiny" ></i>
                        Select questions from a drop down
                    </a>
                    <a class="dropdown-item" href="#" >
                        <i class="bi bi-circle bs-icon-tiny" ></i>
                        Show all questions.
                    </a>
                    <a class="dropdown-item" href="#">
                        <i class="bi bi-check-circle bs-icon-tiny" ></i>
                        Select questions at random
                    </a>
                    <a class="dropdown-item" href="#">
                        <i class="bi bi-circle bs-icon-tiny" ></i>
                        Select questions at random in real time.
                    </a>
                    <a class="dropdown-item" href="#">
                        <i class="bi bi-circle bs-icon-tiny" ></i>
                        Add new questions.
                    </a>

                </template>
            </base-card-drop-down>

        </template>
        <template #default>
                <div class="row">

                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" for="basicSelect">Sort by category</label>
                            <select class="form-select" id="basicSelect">
                                <option>Frontend engineering</option>
                                <option>Backend engineering</option>
                                <option>Other</option>
                            </select>
                        </div>
                    </div>


                        <div class="col-md-12 col-12 mb-1">
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Select new queston" aria-describedby="button-addon2" />
                                <button class="btn btn-outline-primary" id="button-addon2" type="button">
                                    <i class="bi bi-plus bs-icon-btn" ></i>
                                </button>
                            </div>
                        </div>

                    <div class="col-12">
                        <base-button btnColor="primary" btnType="outline" >Add question</base-button>
                    </div>

                    <div class="demo-spacing-0 pt-3">
                        <base-list-tag tagColor="primary" >Category.</base-list-tag>
                        <base-list-tag tagColor="secondary" >lets have some fun.</base-list-tag>
                        <base-list-tag tagColor="secondary" >lets have some fun.</base-list-tag>
                        <base-list-tag tagColor="secondary" >lets have some fun.</base-list-tag>
                        <base-list-tag tagColor="secondary" >lets have some fun.</base-list-tag>
                        <base-list-tag tagColor="secondary" >lets have some fun.</base-list-tag>
                        <base-list-tag tagColor="secondary" >
                        lets have some fun.
                        lets have some fun.
                        lets have some fun.
                        lets have some fun.
                        lets have some fun.
                        lets have some fun.
                        lets have some fun.
                        lets have some fun.
                        lets have some fun.
                        lets have some fun.
                        </base-list-tag>
                    </div>
                </div>
        </template>
    </base-card-plain>
    <!--Core job detail -->

    </form>
    <!--/ form -->
</template>

<script>


export default{
    props:{
        useExistingJD:{
            default:false,
            type: Boolean,
            required: false
        }
    }
}


</script>
