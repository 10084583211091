<template>
    <!-- form -->
    <form class="validate-form">
    <!-- core job detail -->
    <base-card-plain>
        <template #header>
            <h4 class="card-title">Create New Test</h4>

        </template>
        <template #default>
                <div class="row">

                    <div class="col-12 col-sm-12 mt-2">
                        <div class="mb-2">
                            <label class="form-label" for="account-birth-date">Test name</label>
                            <input type="text" class="form-control" placeholder="Organization name" id="account-birth-date" name="dob" />
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="mb-1 mt-1">
                            <label class="form-label" for="accountTextarea">Test description</label>
                            <textarea class="form-control" id="accountTextarea" rows="2" placeholder="Enter question"></textarea>
                        </div>
                    </div>

                    <div class="col-12">
                        <base-button btnColor="primary" >Save Test</base-button>
                    </div>
                </div>
        </template>
    </base-card-plain>
    <!--Core job detail -->

    </form>
    <!--/ form -->
</template>

<script>


export default{
    props:{
        useExistingJD:{
            default:false,
            type: Boolean,
            required: false
        }
    }
}


</script>
